import React from "react";
import Main from "components/layout/Main";
import HeaderSkew from "components/HeaderSkew";
import Block from "components/Block";
import ContentGrid from "components/ContentGrid";
import Heading from "components/Heading";
import PointerParent from "components/PointerParent";
import RawHTMLStyled from "components/RawHTMLStyled";

import ScrollToTop from "components/ScrollToTop";
import backgroundOverlay from "assets/images/header_skew/generic_header.jpg";

export default function writtenConsentPage() {
  const title = "Written Consent";
  const url = "accessibility-statement";

  const points = ["headingslim1"];

  return (
    <Main page={"fqa"} pagetitle={title}>
      <HeaderSkew
        title={title}
        url="faq"
        background={backgroundOverlay}
        appearance="small"
      />

      <PointerParent points={points}>
        <Block layer="1" name="FAQ" guides={1} appearance="regular">
          <ContentGrid appearance="faq">
            <Heading type="h2" appearance="h3-static" id={points[0]}>
              Express Written Consent to Communicate or Call Via Cell Phone or
              Other Means
            </Heading>
            <RawHTMLStyled>
              <>
                <p>
                  We take your privacy seriously. By signing this document, you
                  are providing express written consent for Loanpal, LLC or
                  companies working on our behalf to call you (including through
                  automated means: e.g. autodialing, text, and pre-recorded
                  messaging) via telephone, mobile device or cell phone
                  (including SMS and MMS) and/or via email, even if your
                  telephone number is currently listed on any state, federal, or
                  national Do-Not-Call (DNC) list. In addition, with this
                  consent you also agree to our Privacy Policy provided as a
                  part of your loan documents.
                </p>

                <p>
                  Consent is not required to conduct business with Loanpal and
                  this consent can be withdrawn at any time by calling{" "}
                  <a
                    href="tel:1.844.LOANPAL"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#555", textDecoration: "underline" }}
                  >
                    1.844.LOANPAL
                  </a>{" "}
                  or requesting by mail at Loanpal: 8781 Sierra College Blvd,
                  Roseville, CA 95661.
                </p>

                <p>
                  If you withdraw your consent you will be put on Loanpal’s
                  internal Do-Not-Call list.
                </p>

                <p>
                  For NV residents only: We are providing this notice under
                  state law. You may be placed on our internal Do-Not-Call list
                  by calling{" "}
                  <a
                    href="tel:1.844.LOANPAL"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#555", textDecoration: "underline" }}
                  >
                    1.844.LOANPAL
                  </a>
                  . Nevada asks us to provide their contact information. Office
                  Bureau of Consumer Protection, Office of the Nevada Attorney
                  General, 555 E. Washington St. Suite 3900, Las Vegas, NV
                  89101. Phone:
                  <a
                    href="tel:-702-486-3132"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#555", textDecoration: "underline" }}
                  >
                    (702) 486-3132
                  </a>
                  ; Email:{" "}
                  <a
                    href="mailto:bcinfo@ag.state.nv.us."
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "underline" }}
                  >
                    bcinfo@ag.state.nv.us.
                  </a>{" "}
                </p>

                <p>
                  For CA residents only: Under CA law we will not share
                  information we collect about you with companies outside of
                  Loanpal, LLC unless the law allows.
                </p>
              </>
            </RawHTMLStyled>
          </ContentGrid>
        </Block>
      </PointerParent>
      <ScrollToTop />
    </Main>
  );
}
